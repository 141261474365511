.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}

.skills__container > div {
    background: transparent;
    padding: 2rem 2.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    border-color: var(--color-primary);
    box-shadow: 1px 1px 6px 6px rgba(166, 164, 157, 0.403);;
    transition: var(--transition);
}

.skills__container > div:hover {
    background: var(--color-bg-variant);
    border-color: var(--color-primary);
    cursor: default;
    /* box-shadow: 1px 1px 6px 8px rgba(87, 140, 178, 0.771); */
}

.skills__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skills__details {
    display: flex;
    gap: 1rem;
}

.skills__details-icon {
    margin-top: 6px;
    color: var(--color-primary)
}

/* ++++++++++++++++ MEDIA QUERIES (MEDIUM DEVICES) +++++++++ */
@media screen and (max-width: 1024px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .skills__content {
        padding: 1rem;
    }
}

/* ++++++++++++++++ MEDIA QUERIES (SMALL DEVICES) +++++++++ */
@media screen and (max-width: 600px) {
    .skills__container {
        gap: 1rem;
    }

    .skills__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}