.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 9/10.5;
    border-radius: 2rem;
    /* background: linear-gradient(45deg, transparent, var(--color-dark), transparent); */
    display: grid;
    place-items: center;

}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    /* transform: rotate(10deg); */
    transition: var(--transition);
    box-shadow: 1px 1px 4px 4px rgba(46, 74, 94, 0.771);
}

.about__me-image:hover {
    transform: rotate(0);
    box-shadow: 1px 1px 4px 4px rgba(87, 140, 178, 0.771);
}

.about__content p {
    margin: 0 0 2.6rem;
    color: var(--color-dark);
}

/* ++++++++++++++++ MEDIA QUERIES (MEDIUM DEVICES) +++++++++ */
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* ++++++++++++++++ MEDIA QUERIES (SMALL DEVICES) +++++++++ */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about_content {
        text-align: center;
    }

    .about_content p {
        margin: 1.5rem 0;
    }
}