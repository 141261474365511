header {
    height: 100vh;
    padding-top: 2rem;
    overflow: hidden;
}

.header__container {
    padding-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 0.25fr;
    padding-left: 5rem;
    height: 100%;
    position: relative;
}

/* ++++++++++++++ CTA ++++++++++++++*/
.cta {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.2rem;
}

.cta > a:hover {
    color: var(--color-dark);
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.6);
}

/* ++++++++++++++ Header Socials ++++++++++++++*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 5rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.header__socials > a:hover {
}

.me {
    /* https://pinetools.com/colorize-image */
    /* background: linear-gradient(var(--color-primary), transparent); */
    width: 16rem;
    height: 16rem;
    /* position: absolute; */
    /* left: calc(50% - 9rem); */
    /* margin-top: 2rem; */
    /* border-radius: 2rem 2rem 2rem 2rem; */
    /* box-shadow: 1px 1px 4px 4px rgba(87, 140, 178, 0.771); */
    overflow: hidden;
}

.btn-header {
    box-shadow: 1px 1px 4px 4px rgba(166, 164, 157, 0.403);
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 9rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ++++++++++++++++ MEDIA QUERIES (MEDIUM DEVICES) +++++++++ */
@media screen and (max-width: 1024px) {
    .header__container {
        padding-top: 3rem;
        display: grid;
        grid-template-columns: 1fr;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 100%;
        /* position: relative; */
    }

    .header {
        height: 68vh;
    }

    section {
        margin-top: 6rem;
    }
}


/* ++++++++++++++++ MEDIA QUERIES (SMALL DEVICES) +++++++++ */
@media screen and (max-width: 600px) {
    .header {
        padding-top: 1rem;
        height: 100vh;
    }

    .header__socials,.scroll__down{
        display: none;
    }
}