.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    padding: 1.2rem;
    border-radius: 2rem;
    border: 2px solid;
    border-color: var(--color-primary);
    box-shadow: 1px 1px 6px 6px rgba(166, 164, 157, 0.403);
    background: transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    background: var(--color-bg-variant);
    border: 2px solid;
    /* box-shadow: 1px 1px 6px 8px rgba(87, 140, 178, 0.771); */
}

.portfolio__item-image {
    height: 8rem;
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    position: relative;
    bottom: 0;
    display: flex;
    padding-bottom: 1rem;
    gap: 1rem;
    padding-top: 1rem;
}

.portfolio__item-title {
    color: var(--color-dark);
    height: 4rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

/* ++++++++++++++++ MEDIA QUERIES (MEDIUM DEVICES) +++++++++ */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


/* ++++++++++++++++ MEDIA QUERIES (SMALL DEVICES) +++++++++ */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}