* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    white-space: pre-line;
    
    --color-bg: rgba(235,224,209,255);
    --color-bg-variant: rgba(166, 164, 157, 0.403);
    --color-primary: rgb(21, 46, 79);
    --color-white: #fff;
    --color-black: black;
    --color-light: rgba(255, 255, 255, 0.6);
    --color-dark: rgb(87, 100, 110);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-ms: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: "Lucida Console", "Times New Roman", sans-serif;
    background: var(--color-bg);
    color: var(--color-primary);
    line-height: 1.7;
    /* background-image: url(../src/assets/bg-texture.png); */
}

/* ++++++++++++ GENERAL STYLES ++++++++++++ */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-dark);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

.text-dark {
    color: var(--color-dark);
}

a { 
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover { 
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);    
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ++++++++++++++++ MEDIA QUERIES (MEDIUM DEVICES) +++++++++ */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ++++++++++++++++ MEDIA QUERIES (SMALL DEVICES) +++++++++ */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-md);
    }

    section > h2{
        margin-top: 2rem;
    }
}